@import "https://unpkg.com/open-props";
@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-ext-wght-normal.293f13c8.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-wght-normal.da952466.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-ext-wght-normal.cd8201f9.woff2") format("woff2-variations");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-wght-normal.483320f2.woff2") format("woff2-variations");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-vietnamese-wght-normal.b00b193f.woff2") format("woff2-variations");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-ext-wght-normal.1792d68d.woff2") format("woff2-variations");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-wght-normal.93ad5660.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-ext-wght-normal.293f13c8.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-wght-normal.da952466.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-ext-wght-normal.cd8201f9.woff2") format("woff2-variations");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-wght-normal.483320f2.woff2") format("woff2-variations");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-vietnamese-wght-normal.b00b193f.woff2") format("woff2-variations");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-ext-wght-normal.1792d68d.woff2") format("woff2-variations");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-wght-normal.93ad5660.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-ext-standard-normal.9a59d866.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-standard-normal.266cfbca.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-ext-standard-normal.9679e484.woff2") format("woff2-variations");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-standard-normal.5d2440d6.woff2") format("woff2-variations");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-vietnamese-standard-normal.b9ebd1e1.woff2") format("woff2-variations");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-ext-standard-normal.c289b2ae.woff2") format("woff2-variations");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-standard-normal.da2b3269.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-ext-wght-normal.293f13c8.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-wght-normal.da952466.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-ext-wght-normal.cd8201f9.woff2") format("woff2-variations");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-wght-normal.483320f2.woff2") format("woff2-variations");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-vietnamese-wght-normal.b00b193f.woff2") format("woff2-variations");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-ext-wght-normal.1792d68d.woff2") format("woff2-variations");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-wght-normal.93ad5660.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-ext-slnt-normal.9a59d866.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-slnt-normal.266cfbca.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-ext-slnt-normal.9679e484.woff2") format("woff2-variations");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-slnt-normal.5d2440d6.woff2") format("woff2-variations");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-vietnamese-slnt-normal.b9ebd1e1.woff2") format("woff2-variations");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-ext-slnt-normal.c289b2ae.woff2") format("woff2-variations");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter Variable;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-slnt-normal.da2b3269.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

:root {
  --primary-color: var(--cyan-3);
  --highlight-color: var(--yellow-4);
  --highlight-dark-color: var(--yellow-6);
  --background-color: var(--gray-12);
  --light-text-color: var(--gray-0);
  --dark-text-color: var(--gray-12);
  --max-content-width: 768px;
  --padding-left: var(--size-8);
}

body {
  font-family: "Inter Variable", var(--font-sans);
  background-color: var(--background-color);
  color: var(--light-text-color);
  min-height: 100vh;
  overflow-y: scroll;
}

a {
  color: inherit;
  -webkit-text-decoration-color: var(--primary-color);
  text-decoration-color: var(--primary-color);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      -webkit-text-decoration-color: var(--highlight-color);
      text-decoration-color: var(--highlight-color);
    }
  }
}

p {
  padding-bottom: var(--size-3);
}

body::-webkit-scrollbar {
  width: 16px;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--background-color);
}

body::-webkit-scrollbar-track {
  background: var(--background-color);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  background-image: var(--primary-color);
  border: 3px solid var(--background-color);
  border-radius: 8px;
}
/*# sourceMappingURL=index.f0bc678c.css.map */
