/* @import '../node_modules/open-props/src/index.css'; */
@import 'https://unpkg.com/open-props';
@import '../node_modules/@fontsource-variable/inter/index.css';
@import '../node_modules/@fontsource-variable/inter/standard.css';
@import '../node_modules/@fontsource-variable/inter/wght.css';
@import '../node_modules/@fontsource-variable/inter/slnt.css';

/* CSS Reset from https://www.joshwcomeau.com/css/custom-css-reset/ */
/* 1. Use a more-intuitive box-sizing model. */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
2. Remove default margin
*/
* {
  margin: 0;
}

/*
3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}

/*
Typographic tweaks!
4. Add accessible line-height
5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* END CSS RESET */

:root {
  --primary-color: var(--cyan-3);
  --highlight-color: var(--yellow-4);
  --highlight-dark-color: var(--yellow-6);
  --background-color: var(--gray-12);
  --light-text-color: var(--gray-0);
  --dark-text-color: var(--gray-12);

  --max-content-width: 768px;
  --padding-left: var(--size-8);
}

body {
  font-family: 'Inter Variable', var(--font-sans);
  background-color: var(--background-color);
  color: var(--light-text-color);
  min-height: 100vh;
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration-color: var(--primary-color);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      text-decoration-color: var(--highlight-color);
    }
  }
}

p {
  padding-bottom: var(--size-3);
}

/* ========================================================= */
/* Scrollbar Styles */
/* ========================================================= */
body::-webkit-scrollbar {
  width: 16px;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--background-color);
}

body::-webkit-scrollbar-track {
  background: var(--background-color);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  background-image: var(--primary-color);
  border-radius: 8px;
  border: 3px solid var(--background-color);
}
